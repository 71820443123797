@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 289px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: 71px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fillColor {
  0% {
    fill: transparent;
  }
  100% {
    fill: #fff;
  }
}

#successAnimationCircle {
    stroke-dasharray: 289px;
}

#successAnimationCheck {
    stroke-dasharray: 71px;
}

#successAnimation.animated {
    animation: 2s ease-out 0s 1 both scaleAnimation;
}

#successAnimationCircle {
    animation: 1.2s ease-in-out 0s 1 both drawCircle;
}

#successAnimationCheck {
    animation: 1.2s ease-in-out 0s 1 both drawCheck;
}