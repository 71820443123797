@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url("../fonts/Rubik.woff2") format("woff2");
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* body { 
  padding-right: 0 !important;
  overflow: visible !important;
} */

body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}

html {
  font-family: "Rubik", sans-serif;
  scrollbar-color: #fff rgba(156, 163, 175, 0.5);
  scrollbar-width: thin;
}

.grecaptcha-badge {
  visibility: hidden;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  z-index: 5000;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.8);
  border-radius: 0.25rem;
  z-index: 5000;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbdc1;
  z-index: 5000;
}

::-webkit-scrollbar-track {
  background-color: rgba(209, 213, 219, 0.8);
  z-index: 5000;
}

.thin-scrollbar {
  scrollbar-color: #fff rgba(156, 163, 175, 0.5);
  scrollbar-width: thin;
  scroll-padding: 10px 10px 10px 50px;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 0.2rem;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.65);
}

.thin-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.leaflet-container {
  height: 100%;
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.swiper-pagination-div {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  padding-top: 12px;
  padding-bottom: 4px;
}

@media (max-width: 768px) {
  .swiper-pagination-div {
    margin-bottom: 12px;
  }
}

@import "tailwind.css";

@import "submittingOrder.css";
