.cw-chase {
    width: 60px;
    height: 60px;
    position: relative;
    animation: cw-chase 2.5s infinite linear both;
}
  
.cw-chase-dot, .cw-chase-dot-primary {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: cw-chase-dot 2.0s infinite ease-in-out both;
}

.cw-chase-dot-primary:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: var(--primary);
    border-radius: 100%;
    animation: cw-chase-dot-before 2.0s infinite ease-in-out both;
}
  
.cw-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: var(--primary-contrast);
    border-radius: 100%;
    animation: cw-chase-dot-before 2.0s infinite ease-in-out both;
}
  
.cw-chase-dot:nth-child(1), .cw-chase-dot-primary:nth-child(1) { animation-delay: -1.1s; }
.cw-chase-dot:nth-child(2), .cw-chase-dot-primary:nth-child(2) { animation-delay: -1.0s; }
.cw-chase-dot:nth-child(3), .cw-chase-dot-primary:nth-child(3) { animation-delay: -0.9s; }
.cw-chase-dot:nth-child(4), .cw-chase-dot-primary:nth-child(4) { animation-delay: -0.8s; }
.cw-chase-dot:nth-child(5), .cw-chase-dot-primary:nth-child(5) { animation-delay: -0.7s; }
.cw-chase-dot:nth-child(6), .cw-chase-dot-primary:nth-child(6) { animation-delay: -0.6s; }
.cw-chase-dot:nth-child(1):before, .cw-chase-dot-primary:nth-child(1):before { animation-delay: -1.1s; }
.cw-chase-dot:nth-child(2):before, .cw-chase-dot-primary:nth-child(2):before { animation-delay: -1.0s; }
.cw-chase-dot:nth-child(3):before, .cw-chase-dot-primary:nth-child(3):before { animation-delay: -0.9s; }
.cw-chase-dot:nth-child(4):before, .cw-chase-dot-primary:nth-child(4):before { animation-delay: -0.8s; }
.cw-chase-dot:nth-child(5):before, .cw-chase-dot-primary:nth-child(5):before { animation-delay: -0.7s; }
.cw-chase-dot:nth-child(6):before, .cw-chase-dot-primary:nth-child(6):before { animation-delay: -0.6s; }
  
@keyframes cw-chase {
    100% { transform: rotate(360deg); } 
}

@keyframes cw-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
}

@keyframes cw-chase-dot-before {
    50% {
        transform: scale(0.4); 
    } 100%, 0% {
        transform: scale(1.0); 
    } 
}