.spinner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50px;
    height: 100%;
}

.spinner > div {
    width: 10px;
    height: 10px;
    background-color: var(--primary);
    flex-shrink: 0;
  
    border-radius: 100%;
    -webkit-animation: sk-bouncedelay 1.8s infinite ease-in-out both;
    animation: sk-bouncedelay 1.8s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 70%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 70%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}